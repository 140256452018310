import React from 'react'

export default function AboutDetail() {
  return (
    <>
    
      
    </>
  )
}
