import React from 'react'
import Layout from './layout'
import VendorsBanner from '../components/VendorsBanner.jsx'

export default function Vendors() {
  return (
    <>
    <Layout page={"vendors"}>
        <VendorsBanner />        
    </Layout>
      
    </>
  )
}
